import React from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import "moment/locale/es-mx";

export class ComponentToPrint extends React.PureComponent {
  render() {
    const { data, qr } = this.props;
    const duplicate = () => {
      const uniqueStands = new Set(
        data.stands.map((item) => {
          if (item.startsWith("CH")) {
            return "Chocolaterías - Nave 2";
          } else if (item.startsWith("M")) {
            return "Mercado del cacao - Nave 1";
          } else if (item.startsWith("EC")) {
            return "Expo Café - Nave 1";
          } else if (item.startsWith("E")) {
            return "Expositores - Nave 2";
          } else if (item.startsWith("CC")) {
            return "CavaCao - Nave 2";
          } else if (item.startsWith("H")) {
            return "Haciendas - Nave 2";
          }
        })
      );

      return Array.from(uniqueStands).join(", ");
    };
    return (
      <Grid
        container
        spacing={1}
        direction="column"
        justifyContent="space-between"
        height="100vh"
        width="100%"
        paddingX={8}
        paddingY={2}
        sx={{
          "& span": {
            fontWeight: "bold",
          },
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Grid item>
            <img
              src="https://imagenturistica.tabasco.gob.mx/_data/i/upload/2023/10/06/LogoFestival.svg"
              style={{ width: 230 }}
              alt="Image 1"
            />
          </Grid>
          <Grid item>
            <img
              src="https://imagenturistica.tabasco.gob.mx/_data/i/upload/2023/10/06/LogoPromotur.svg"
              style={{ width: 230 }}
              alt="Image 1"
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="column"
          spacing={1}
          alignItems="flex-end"
        >
          <Grid item>
            <Typography>
              Villahermosa, Tabasco a{" "}
              {` ${moment
                .utc(data.feedbacks[0].createAt * 1000)
                .format("DD [de] MMMM [de] YYYY")}`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography fontWeight="bold">Folio: {data.folio}</Typography>
          </Grid>
        </Grid>

        <Grid item alignSelf="center">
          <Typography fontWeight="bold" textAlign="center">
            {data.trueque
              ? "FORMATO DE PAGO EN FORMA DE TRUEQUE PARA EXPOSITORES"
              : "FORMATO DE PAGO PASE DE CAJA PARA EXPOSITORES"}
          </Typography>
        </Grid>
        <Grid
          item
          container
          width={700}
          style={{ margin: "0 auto" }}
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography>NOMBRE DEL EXPOSITOR: {data.nombre}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>RFC: {data.rfc}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography>EMPRESA: {data.empresa}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>MUNICIPIO: {data.municipio}</Typography>
          </Grid>
          <Grid
            item
            container
            spacing={2}
            direction="row"
            justifyContent="space-between"
          >
            <Grid item>
              <Typography>ZONA DE EXPOSICIÓN: {duplicate()}</Typography>
            </Grid>
            <Grid item>
              <Typography>
                NÚMERO DE STAND: {data.stands.map((item) => `${item},`)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            direction="row"
            justifyContent="space-between"
          >
            <Grid item>
              <Typography>
                No. METROS CUADRADOS: {data.squareMeter} M2
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                COSTO POR METRO CUADRADO: ${data.pricePerMeter}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Typography variant="caption">
              Observaciones: {data.feedbacks[0].observations}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="column"
          width={700}
          style={{ margin: "0 auto" }}
          spacing={2}
        >
          <Grid
            item
            marginLeft={2}
            style={{ paddingTop: 0, paddingBottom: 0 }}
            bgcolor="#BFBFBF"
            textAlign="center"
          >
            <Typography fontWeight="bold">
              MONTO TOTAL A PAGAR: ${data.total}
            </Typography>
          </Grid>

          {data.trueque ? (
            <Grid item>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>DESCRIPCIÓN DEL PRODUCTO</TableCell>
                      <TableCell align="right">CANTIDAD</TableCell>
                      <TableCell align="right">FECHA DE ENTREGA</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.detallesTrueque.map((row) => (
                      <TableRow
                        key={row.nombre}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.nombre}
                        </TableCell>
                        <TableCell align="right">{row.cantidad}</TableCell>
                        <TableCell align="right">
                          {moment(row.fechaEntrega.toDate()).format("L")}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ) : (
            <Grid item>
              <img
                src="https://imagenturistica.tabasco.gob.mx/_data/i/upload/2023/10/17/20231017152321-37888f91-me.png"
                style={{ width: "100%" }}
                alt="Image 1"
              />
            </Grid>
          )}

          {!data.trueque && (
            <Grid item container>
              <Grid
                item
                textAlign="center"
                width={300}
                style={{ margin: "0 auto" }}
              >
                {qr}
              </Grid>
            </Grid>
          )}

          {data.trueque ? (
            <Grid item container>
              <Grid item container spacing={1} paddingRight={2} xs={9}>
                <Grid item textAlign="justify">
                  <Typography variant="body2">
                    Los productos deberán venir acompañados de una tarjeta de
                    presentación c/u donde se incluya nombre y logotipo de la
                    empresa, datos de contacto y, se recomienda se incluya un Qr
                    del catálogo de sus productos.
                  </Typography>
                </Grid>
                <Grid item textAlign="justify">
                  <Typography variant="body2">
                    La vigencia de este Formato de Trueque es única y
                    exclusivamente hasta la fecha de entrega de los productos.
                    Si el producto no es entregado en la fecha indicada, el
                    stand estará disponible para otro solicitante interesado.
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={3}>
                <Grid
                  item
                  textAlign="center"
                  width={300}
                  style={{ margin: "0 auto" }}
                >
                  {qr}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item textAlign="center">
              <Typography variant="body2">
                La vigencia de este pase de caja es de 3 días hábiles a partir
                de la fecha en que fue expedido. Si el pago no es realizado en
                esa fecha, el stand quedará disponible para otro solicitante
                interesado. Únicamente se aceptarán depósitos realizados en la
                Institución Bancaria indicada.
              </Typography>
              <Typography
                fontWeight="bold"
                style={{ textDecoration: "underline" }}
              >
                NO TRANSFERENCIAS
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}
